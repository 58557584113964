<script setup>
/******************************************************************************
 * Component: Form button
 *****************************************************************************/
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import LoadingSpinner from "Components/Loading/LoadingSpinner.vue";
import { useAppStore } from "Stores/app";
defineProps({
    text: { type: String, required: true },
    iconName: { type: String, default: "" },
    iconPosition: {
        type: String,
        default: "",
        validator: (value) => ["left", "right", ""].includes(value),
    },
    importance: {
        // "default" || "primary"
        type: String,
        default: "default",
        validator: (value) => ["default", "primary", "low"].includes(value),
    },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
});
const appStore = useAppStore();
</script>

<template>
    <button
        class="form-button"
        :class="importance"
        :disabled="disabled"
        :aria-disabled="disabled"
    >
        <svg-icon
            v-if="iconPosition === 'left' && !appStore.requesting"
            :name="iconName"
        />
        <loading-spinner
            v-if="iconPosition === 'left' && appStore.requesting"
        />
        <span>{{ text }}</span>
        <svg-icon
            v-if="iconPosition === 'right' && !appStore.requesting"
            :name="iconName"
        />
        <loading-spinner
            v-if="iconPosition === 'right' && appStore.requesting"
        />
    </button>
</template>
