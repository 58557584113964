<script setup>
/******************************************************************************
 * PageComponent: MeterRegister (Zählwerk) reading requirements
 *****************************************************************************/
import { useReadingStore } from "Stores/reading";
import { formatReadingValue } from "@/handlers/useFormatting";
import { isLastReadingOk, isMinMaxReadingOk } from "@/handlers/useReading";
const data = useReadingStore();
const props = defineProps({
    meterRegisterId: {
        type: String,
        required: true,
    },
});
const meterRegisterData = data.getMeterRegisterData(props.meterRegisterId);
</script>

<template>
    <ul class="requirement">
        <li
            v-if="meterRegisterData.lastReadingValue"
            :class="{ ok: isLastReadingOk(meterRegisterId) }"
        >
            Letzter Zählerstand:
            <strong>{{
                formatReadingValue(meterRegisterData.lastReadingValue)
            }}</strong>
            {{ meterRegisterData.meterUnit }}
            vom
            {{ meterRegisterData.lastReadingDate }}
        </li>
        <li
            v-if="meterRegisterData.meterValueMin !== 0"
            :class="{ ok: isMinMaxReadingOk(meterRegisterId) }"
        >
            Zählerstand erwartet zwischen
            <strong>{{
                formatReadingValue(meterRegisterData.meterValueMin)
            }}</strong>
            und
            <strong>{{
                formatReadingValue(meterRegisterData.meterValueMax)
            }}</strong>
            {{ meterRegisterData.meterUnit }}
        </li>
    </ul>
</template>

<style lang="scss" scoped>
.requirement {
    display: flex;
    flex-direction: column;

    padding: 0;
    border: 1px solid get($client, "inputBorderColor");
    margin: 0;

    list-style: none;
    border-radius: get($sizes, "radius");

    @include respond-to("medium") {
        margin: 0 0 #{get($sizes, "base") * 0.4} 0;
    }

    > li {
        padding: #{get($sizes, "base") * 0.4};

        border-radius: get($sizes, "radius");

        font-size: #{get($sizes, "base") - 2px};

        &:not(:last-child) {
            border-bottom: get($sizes, "border") solid
                get($client, "inputBorderColor");
        }

        &.ok {
            background-color: palette("state", "success");
            color: palette("text", "bright");

            strong {
                color: palette("text", "bright");
            }
        }
    }
}

strong {
    color: get($client, "meterReadingValueColor");

    font-weight: 400;
}
</style>
