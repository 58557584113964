<script setup>
/******************************************************************************
 * PageComponent: Confirm
 *****************************************************************************/
import PageSection from "Components/PageSection/PageSection.vue";
import ConfirmMeters from "./ConfirmMeters.vue";
import FormNavRow from "Components/Form/FormNavRow.vue";
import FormButton from "Components/Form/FormButton.vue";
import FormGlobalErrorMessages from "Components/Form/FormGlobalErrorMessages.vue";
import ConfirmAcceptPrivacy from "./ConfirmAcceptPrivacy.vue";
import ReadingsQueued from "./ReadingsQueued.vue";
import { useReadingStore } from "Stores/reading";
import { useAppStore } from "Stores/app";
import { useRouter } from "vue-router";
import { ref } from "vue";
const router = useRouter();
const data = useReadingStore();
const appStore = useAppStore();
const readings = data.readings.filter(
    (reading) => reading.meterReadingValue.length > 0
);
const privacyAccepted = ref(false);
const onPrivacyChanged = (value) => (privacyAccepted.value = value);
const showModal = ref(false);
/**
 * @function submit meter reading data. we add meter data to reading object
 * to make it easier in the server (visual information only, validation still server-side)
 */
const onForwardClick = () => {
    if (appStore.online) {
        data.submitReadings(router);
    } else {
        data.queueReadings(true);
    }
};
const onBackwardClick = () => router.push({ name: "MeterReading" });
</script>

<template>
    <main class="page">
        <readings-queued
            v-if="data.queuedReading || showModal"
            @close="showModal = false"
        />
        <page-section hdl="Zusammenfassung">
            <p>
                Bitte überprüfen Sie Ihre Angaben. Wenn alles korrekt ist,
                melden Sie Ihre Zählerstände an uns über den Button rechts
                unten.
            </p>
            <p>
                <strong>Falls nötig können Sie Ihre Daten ändern</strong> indem
                Sie in der Navigation oben zum Punkt
                <strong>"Zählerstände"</strong> navigieren oder unten den Button
                <strong>"Zurück"</strong> verwenden.
                <strong v-if="data.meters.length > readings.length">
                    Sie können das ebenfalls nutzen um die nicht abgelesenen
                    Zähler abzulesen.
                </strong>
            </p>
        </page-section>
        <confirm-meters :readings="readings" />
        <confirm-accept-privacy
            v-if="data.config.requirePrivacyAcceptance"
            @change="onPrivacyChanged"
        />
        <form-global-error-messages
            v-if="data.error"
            :error-text="data.error"
            :top="1"
        />
        <form-nav-row :top="1">
            <template #back>
                <form-button
                    text="Zurück"
                    importance="default"
                    :disabled="appStore.requesting"
                    @click="onBackwardClick"
                />
            </template>
            <template #forward>
                <form-button
                    :text="`${
                        readings.length === 1 ? 'Zählerstand' : 'Zählerstände'
                    } melden`"
                    importance="primary"
                    :disabled="
                        appStore.requesting ||
                        (data.config.requirePrivacyAcceptance &&
                            !privacyAccepted)
                    "
                    @click="onForwardClick"
                />
            </template>
        </form-nav-row>
    </main>
</template>
