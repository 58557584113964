<script setup>
/******************************************************************************
 * PageComponent: Success
 *****************************************************************************/
import SuccessAnimation from "./SuccessAnimation.vue";
import FormNavRow from "Components/Form/FormNavRow.vue";
import ContactDataSubmission from "./ContactData/ContactDataSubmission.vue";
import PageSection from "Components/PageSection/PageSection.vue";
import { format } from "date-fns";
import { useReadingStore } from "Stores/reading";
import { CLIENT_SHORT } from "@/config";
const data = useReadingStore();
const fileName = () =>
    `${CLIENT_SHORT.replace(" ", "")}-Zählerstandsmeldung-${format(
        new Date(),
        "dd.MM.yyyy"
    )}.pdf`;
const allowContactDataSubmission = () => {
    let allow = false;
    data.customers.forEach((customer) => {
        const c = customer.contactSubmission;
        if (c.email || c.phone || c.phoneMobile || c.fax) {
            allow = true;
        }
    });
    return allow;
};
</script>

<template>
    <main class="page">
        <div class="success">
            <success-animation :size="192" />
        </div>
        <page-section hdl="Danke schön!">
            <p>Wir haben Ihre Zählerstände erhalten - vielen Dank!</p>
            <p>
                Um sich abzumelden schließen Sie einfach Ihr Browser Fenster
                oder benutzen Sie den Button oben rechts.
            </p>
            <p>
                Wenn Sie für eine weitere Kundennummer Zählerstände melden
                möchten, dann melden Sie sich ab und melden sich mit den Daten
                der neuen Kundennummer an.
            </p>
            <p>
                Wenn Sie wünschen können Sie sich für Ihre Unterlagen auch eine
                Druckansicht herunterladen.
            </p>
        </page-section>
        <form-nav-row :top="1">
            <template #forward>
                <a
                    class="form-button primary"
                    target="_blank"
                    :href="data.printFile"
                    :download="fileName()"
                >
                    Druckansicht herunterladen
                </a>
            </template>
        </form-nav-row>
        <contact-data-submission v-if="allowContactDataSubmission()" />
    </main>
</template>

<style lang="scss" scoped>
.success {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
